module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Alpha Brands","short_name":"Alphabrands","start_url":"/","background_color":"#F49725","theme_color":"#3380CC","display":"standalone","icon":"/opt/build/repo/src/images/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f8f010d260d14ea104f59d43d1a11eef"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
